import { createRouter, createWebHistory } from "vue-router";

//const Home = () => import("./layouts/Home.vue");
const Portfolio = () => import("./layouts/Portfolio.vue");
import DynamicRouteComponent from "./layouts/DynamicRouteComponent.vue";
const Main = () => import("./pages/Main.vue");
const ReviewPage = () => import("./pages/ReviewPage.vue");
const CourseList = () => import("./layouts/CourseList.vue");
const CourseDetails = () => import("./layouts/CourseDetails.vue");
const Login = () => import("./layouts/Login.vue");
const Signup = () => import("./layouts/Signup.vue");
const Success = () => import("./layouts/Success.vue");
const Profile = () => import("./layouts/UserProfile.vue");
const Pricing = () => import("./layouts/Pricing.vue");
const ResetPassword = () => import("./layouts/ResetPassword.vue");
const ForgotPassword = () => import("./layouts/ForgotPassword.vue");
const ResendConfirmation = () => import("./layouts/ResendConfirmation.vue");
const Unsubscribe = () => import("./layouts/Unsubscribe.vue");
const NotFound = () => import("./layouts/NotFound.vue");
const SupportLogin = () => import("./layouts/SupportLogin.vue");
const Terms = () => import("./layouts/Terms.vue");
const Privacy = () => import("./layouts/Privacy.vue");
const Replayer = () => import("./layouts/Replayer.vue");
const Review = () => import("./layouts/Review.vue");
const ReviewPublic = () => import("./layouts/ReviewPublic.vue");
const Features = () => import("./layouts/Features.vue");

import { useUserStore } from "./stores/userStore.js";

let host = window.location.host;
let subdomain = host.split(".")[0];
const routes = [
  {
    path: "/",
    component: Main,
    name: "Home",
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "/",
        component: DynamicRouteComponent,
        name: "Dynamic",
        meta: {
          requiresAuth: false,
          title: "Review My eLearning - Revolutionizing Reviews",
        },
      },
      {
        path: "/features",
        component: Features,
        name: "Features",
        meta: {
          requiresAuth: false,
          title: "Review My eLearning - Features",
        },
      },
      {
        path: "/portfolio",
        component: Portfolio, // replace with your actual component
        name: "Org Portfolio",
        meta: {
          requiresAuth: false,
          title: subdomain.toUpperCase() + " - Portfolio",
          ignoreTheme: false,
        },
      },
      {
        path: "/courses",
        component: CourseList,
        name: "Courses",
        meta: {
          requiresAuth: true,

          title: "Review My eLearning - Courses",
        },
      },
      {
        path: "/course/:id",
        component: CourseDetails,
        name: "CourseDetails",
        meta: {
          requiresAuth: true,

          title: "Review My eLearning - Course Details",
        },
      },
      {
        path: "/pricing",
        component: Pricing,
        name: "Pricing",
        meta: {
          requiresAuth: false,

          title: "Review My eLearning - Pricing",
          ignoreTheme: true,
        },
      },
      {
        path: "/cancel",
        component: Pricing,
        name: "Cancel",
        meta: {
          requiresAuth: false,

          title: "Review My eLearning - Cancel",
        },
      },
      {
        path: "/login",
        component: Login,
        name: "Login",
        meta: {
          requiresAuth: false,
          title: "Review My eLearning - Login",
        },
      },
      {
        path: "/signup",
        component: Signup,
        name: "Signup",
        meta: {
          requiresAuth: false,
          title: "Review My eLearning - Login",
        },
      },
      {
        path: "/support_sign_in",
        component: SupportLogin,
        name: "Support Login",
        meta: {
          requiresAuth: false,

          title: "Review My eLearning - Support Login",
        },
      },
      {
        path: "/success/:sessionToken",
        component: Success,
        name: "Success",
        meta: {
          requiresAuth: false,
          title: "Review My eLearning - Success",
        },
      },
      {
        path: "/profile",
        component: Profile,
        name: "Profile",
        meta: {
          requiresAuth: true,

          title: "Review My eLearning - Profile",
        },
      },
      {
        path: "/reset-password/:token",
        component: ResetPassword,
        name: "Reset Password",
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/forgot-password/",
        component: ForgotPassword,
        name: "Forgot Password",
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/resend-confirmation/",
        component: ResendConfirmation,
        name: "Resend Confirmation",
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/join",
        component: Pricing,
        name: "Join",
        meta: {
          requiresAuth: false,

          title: "Review My eLearning - Join",
          ignoreTheme: true,
        },
      },
      {
        path: "/join/:promo",
        component: Pricing,
        name: "Promo",
        meta: {
          requiresAuth: false,

          title: "Review My eLearning - Promo",
          ignoreTheme: true,
        },
      },

      {
        path: "/unsubscribe/",
        component: Unsubscribe,
        name: "Unsubscribe",
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/terms/",
        component: Terms,
        name: "Terms",
        meta: {
          requiresAuth: false,

          title: "Review My eLearning - Terms of Service",
        },
      },
      {
        path: "/privacy/",
        component: Privacy,
        name: "Privacy",
        meta: {
          requiresAuth: false,

          title: "Review My eLearning - Privacy Policy",
        },
      },

      {
        path: "/:pathMatch(.*)*", // catch all 404 - must be last!
        name: "NotFound",
        component: NotFound,
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: "/",
    component: ReviewPage,
    name: "ReviewPage",
    meta: {
      requiresAuth: false,
      title: "Review My eLearning - Revolutionizing Reviews",
    },
    children: [
      {
        path: "/review/:rid",
        component: Review,
        name: "Review",
        meta: {
          requiresAuth: false,
          hideNav: true,
          title: "Review My eLearning - Review",
        },
      },
      {
        path: "/view/:rid",
        component: Review,
        name: "View",
        meta: {
          requiresAuth: false,
          hideNav: true,
          title: "Review My eLearning - View",
        },
      },
      {
        path: "/comment/:cid",
        component: Review,
        name: "View Comment",
        meta: {
          requiresAuth: true,
          hideNav: true,
          title: "Review My eLearning - View Comment",
        },
      },
      {
        path: "/replay/:rid",
        component: Replayer,
        name: "Replay",
        meta: {
          requiresAuth: false,
          hideNav: true,
          title: "Review My eLearning - Replay",
        },
      },

      {
        path: "/public-review/:rid",
        component: ReviewPublic,
        name: "Public Review",
        meta: {
          requiresAuth: false,
          hideNav: true,
          title: "Review My eLearning - Public Review",
        },
      },
      {
        path: "/public-course/:cid",
        component: ReviewPublic,
        name: "Public Course",
        meta: {
          requiresAuth: false,
          hideNav: true,
          title: "Review My eLearning - Public Course",
        },
      },
      {
        path: "/course-review/:cid",
        component: ReviewPublic,
        name: "Course Review",
        meta: {
          requiresAuth: false,
          hideNav: true,
          title: "Review My eLearning - Course Review",
        },
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  let mergedQuery = { ...to.query };

  if (from.query && Object.keys(from.query).length > 0) {
    mergedQuery = { ...to.query, ...from.query };
  }

  if (to && to.meta && to.meta.requiresAuth) {
    if (userStore.user.email) {
      if (!isEqual(to.query, mergedQuery)) {
        next({ ...to, query: mergedQuery });
      } else {
        next();
      }
    } else {
      next("/login");
    }
  } else {
    if (to.path === "/" && userStore.user.email) {
      next("/courses"); // Redirect to /courses if the user is authenticated and navigates to /
    } else if (!isEqual(to.query, mergedQuery)) {
      next({ ...to, query: mergedQuery });
    } else {
      next();
    }
  }
});
router.afterEach((to, from, next) => {
  if (to && to.meta && to.meta.title) {
    document.title = to.meta.title;
    //set path for hsq
    if (window._hsq) {
      _hsq.push(["setPath", to.path]);
      _hsq.push(["trackPageView"]);
    }
  } else {
    document.title = "Review My eLearning - Revolutionizing Reviews";
  }
});

function isEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export default router;
